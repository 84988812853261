var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pane_container" },
    [
      _vm._m(0),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "parameterForm_css",
          attrs: { model: _vm.controlForm, "label-width": "160px" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 16 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "mini" },
                          on: { click: _vm.onClear },
                        },
                        [_vm._v("清空")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(1),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "parameterForm_css",
          attrs: { model: _vm.controlForm, "label-width": "160px" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 16 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "mini" },
                          on: { click: _vm.onStop },
                        },
                        [_vm._v("急停")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(2),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "parameterForm_css",
          attrs: { model: _vm.controlForm, "label-width": "160px" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 16 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入流量" },
                    model: {
                      value: _vm.controlForm.flow,
                      callback: function ($$v) {
                        _vm.$set(_vm.controlForm, "flow", $$v)
                      },
                      expression: "controlForm.flow",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success", size: "mini" },
                          on: { click: _vm.onSetFlow },
                        },
                        [_vm._v("设置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(3),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "parameterForm_css",
          attrs: { model: _vm.controlForm, "label-width": "160px" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 16 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { "label-width": "0px" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择闸位" },
                          model: {
                            value: _vm.controlForm.gatePosition,
                            callback: function ($$v) {
                              _vm.$set(_vm.controlForm, "gatePosition", $$v)
                            },
                            expression: "controlForm.gatePosition",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success", size: "mini" },
                          on: { click: _vm.onSetGatePosition },
                        },
                        [_vm._v("设置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(4),
      _c(
        "el-form",
        {
          ref: "realform",
          staticClass: "parameterForm_css",
          attrs: { model: _vm.realform, "label-width": "160px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "瞬时流量" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.realform.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.realform, "password", $$v)
                  },
                  expression: "realform.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "累计水量" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.realform.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.realform, "password", $$v)
                  },
                  expression: "realform.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报警" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.realform.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.realform, "password", $$v)
                  },
                  expression: "realform.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.realform.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.realform, "password", $$v)
                  },
                  expression: "realform.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "闸前水位" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.realform.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.realform, "password", $$v)
                  },
                  expression: "realform.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "闸后水位" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.realform.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.realform, "password", $$v)
                  },
                  expression: "realform.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "闸位" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.realform.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.realform, "password", $$v)
                  },
                  expression: "realform.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "电机实时工作数据" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.realform.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.realform, "password", $$v)
                  },
                  expression: "realform.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "工作状态" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.realform.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.realform, "password", $$v)
                  },
                  expression: "realform.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "水位" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.realform.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.realform, "password", $$v)
                  },
                  expression: "realform.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "水深" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.realform.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.realform, "password", $$v)
                  },
                  expression: "realform.password",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "clear" }),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("自报")]
              ),
              _c("el-button", { attrs: { size: "mini" } }, [_vm._v("取消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "box_description",
        staticStyle: { "margin-bottom": "20px" },
      },
      [
        _c("span", { staticClass: "box_span" }, [
          _vm._v("清空监测站历史数据单元"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "box_description",
        staticStyle: { "margin-bottom": "20px" },
      },
      [_c("span", { staticClass: "box_span" }, [_vm._v("闸门电机急停")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "box_description",
        staticStyle: { "margin-bottom": "20px" },
      },
      [_c("span", { staticClass: "box_span" }, [_vm._v("流量控制")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "box_description",
        staticStyle: { "margin-bottom": "20px" },
      },
      [_c("span", { staticClass: "box_span" }, [_vm._v("闸位控制")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "box_description",
        staticStyle: { "margin-bottom": "20px" },
      },
      [_c("span", { staticClass: "box_span" }, [_vm._v("实时/自报")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }